import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./App.css";
import Body from "./components/Body";
import Footer from "./components/Footer";
import Header from "./components/Header";
import PrivacyStatement from "./components/PrivacyStatements";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/privacy-verklaring" element={<PrivacyStatement />} />
          <Route path="/:section" element={<Body />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
