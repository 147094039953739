import React from "react";
import { Link } from "react-router-dom";
import logo from "../DatomationCropped.png";
import "./Header.css";

const Header = () => {
  return (
    <header className="header">
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={logo} className="header-logo" alt="header-logo"></img>
        <h1>Datomation</h1>
      </div>
      <nav>
        <ul>
          <li>
            <Link to="/home">Home</Link>
          </li>
          {/* <li>
            <Link to="/about">About</Link>
          </li> */}
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
