function PrivacyStatement() {
  return (
    <main
      className="body"
      style={{
        color: "#FFF",
        textAlign: "left",
      }}
    >
      <h1>Privacyverklaring Datomation</h1>
      <p>
        <strong>Datum:</strong> 05-08-2024
      </p>

      <h2>1. Inleiding</h2>
      <p>
        Datomation, gevestigd aan Guldenpromenade 3, 1060SB, Amsterdam, te
        vinden onder KvK nummer 86287818, is verantwoordelijk voor de verwerking
        van persoonsgegevens zoals weergegeven in deze privacyverklaring.
        Datomation hecht veel waarde aan de bescherming van uw persoonsgegevens.
        Deze privacyverklaring legt uit welke gegevens Datomation verzamelt, hoe
        Datomation deze gegevens gebruikt, bewaart en beveiligt, en welke
        rechten u hebt met betrekking tot uw persoonsgegevens.
      </p>

      <h2>2. Welke gegevens verzamelt Datomation?</h2>
      <p>
        In principe werkt Datomation alleen voor bedrijven en verwerkt dus
        alleen bedrijfsgegevens. Het kan zo zijn dat Datomation gegevens van
        contactpersonen opslaat. Indien dit zo is verzamelt Datomation de
        volgende persoonsgegevens van een contactpersoon:
      </p>
      <ul>
        <li>Voor- en achternaam</li>
        {/* <li>Adresgegevens</li> */}
        <li>E-mailadres (bij voorkeur zakelijk)</li>
        <li>Telefoonnummer (bij voorkeur zakelijk)</li>
        {/* <li>Bankrekeningnummer (voor facturatiedoeleinden)</li> */}
      </ul>

      <h2>3. Doeleinden van de gegevensverzameling en grondslag</h2>
      <p>
        Datomation verwerkt uw persoonsgegevens voor de volgende doeleinden:
      </p>
      <ul>
        <li>Het opstellen en verzenden van facturen</li>
        <li>Het onderhouden van contact met u</li>
        <li>
          Het voldoen aan wettelijke verplichtingen (bijvoorbeeld
          belastingaangifte)
        </li>
      </ul>

      <h2>4. Bewaartermijn</h2>
      <p>
        Datomation bewaart uw persoonsgegevens niet langer dan strikt
        noodzakelijk is om de doelen te realiseren waarvoor uw gegevens worden
        verzameld. Factuurgegevens worden volgens de wettelijke verplichtingen 7
        jaar bewaard.
      </p>

      <h2>5. Delen van persoonsgegevens met derden</h2>
      <p>
        Datomation deelt uw persoonsgegevens uitsluitend met derden indien dit
        noodzakelijk is voor de uitvoering van de overeenkomst met u of om te
        voldoen aan een wettelijke verplichting. Denk hierbij aan:
      </p>
      <ul>
        <li>Boekhouders</li>
        <li>Belastingdienst</li>
      </ul>

      <h2>6. Beveiliging van persoonsgegevens</h2>
      <p>
        Datomation neemt passende technische en organisatorische maatregelen om
        uw persoonsgegevens te beveiligen tegen verlies of onrechtmatige
        verwerking. Deze maatregelen omvatten:
      </p>
      <ul>
        <li>Beveiligde opslag van gegevens</li>
        <li>Toegang tot gegevens beperken tot geautoriseerde personen</li>
        <li>Regelmatige updates en beveiliging van software</li>
      </ul>

      <h2>7. Uw rechten</h2>
      <p>
        U hebt het recht om uw persoonsgegevens in te zien, te corrigeren of te
        verwijderen. Daarnaast hebt u het recht om uw eventuele toestemming voor
        de gegevensverwerking in te trekken of bezwaar te maken tegen de
        verwerking van uw persoonsgegevens door Datomation. U kunt een verzoek
        tot inzage, correctie, verwijdering of gegevensoverdraging van uw
        persoonsgegevens of verzoek tot intrekking van uw toestemming of bezwaar
        op de verwerking van uw persoonsgegevens sturen naar
        thomas@datomation.nl.
      </p>

      <p>
        U bent niet verplicht om persoonsgegevens te delen. Datomation kan dan
        alleen niet volledig de facturen adresseren aan bedrijven of een
        overeenkomst sluiten met natuurlijke personen of afgeleiden daarvan.
      </p>
      <p>Datomation maakt geen gebruik van geautomatiseerde bedrijfsvoering.</p>

      <h2>8. Contactgegevens</h2>
      <p>
        Voor vragen over deze privacyverklaring of de verwerking van uw
        persoonsgegevens kunt u contact met ons opnemen via onderstaande
        contactgegevens:
      </p>
      <p>
        Datomation
        <br />
        Guldenpromenade 3, 1060SB, Amsterdam
        <br />
        thomas@datomation.nl
        <br />
        06-23356430
      </p>

      <h2>9. Wijzigingen in deze privacyverklaring</h2>
      <p>
        Datomation behoudt het recht voor om deze privacyverklaring te wijzigen.
        Wijzigingen zullen op onze website worden gepubliceerd. Het is raadzaam
        om deze privacyverklaring regelmatig te raadplegen, zodat u van
        eventuele wijzigingen op de hoogte bent.
      </p>
    </main>
  );
}

export default PrivacyStatement;
