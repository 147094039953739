import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <p>&copy; 2024 Datomation. All rights reserved.</p>
      <p style={{ fontSize: 12, marginBottom: 0 }}>
        KVK: 86287818, BTW-ID: NL004223919B72
      </p>
      <p style={{ fontSize: 12, marginTop: 0, marginBottom: 0 }}>
        Guldenpromenade 3, 1060SB, Amsterdam
      </p>
      <nav>
        <ul>
          <li>
            <Link
              to="/privacy-verklaring"
              style={{ color: "#FFF", fontSize: 10 }}
            >
              Privacyverklaring
            </Link>
          </li>
        </ul>
      </nav>
    </footer>
  );
};

export default Footer;
