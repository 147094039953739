import { useState } from "react";
import BIImage from "../../Images/BIWebsite.png";
import DataImage from "../../Images/DataAnalysisWebsite.png";
import SoftwareImage from "../../Images/SoftwareWebsite.png";
import "./Activities.css";

const activities = [
  {
    name: "Data Analysis",
    image: DataImage,
    title: "Data Analysis & Insights",
    shortText: "Learn how I can provide valuable insights from data.",
    longText: `Data Analysis & Insights involves the systematic process of inspecting, cleaning, transforming, and modeling data with 
      the goal of discovering useful information, informing conclusions, and supporting decision-making. 
      This activity leverages advanced statistical, computational, and analytical techniques to interpret complex datasets 
      and extract actionable insights.
      Through the use of sophisticated tools and methodologies, I help organizations turn raw data into strategic assets, enabling them to understand their operations better, identify growth opportunities, optimize processes, and ultimately drive business success.`,
  },
  {
    name: "Software Engineering",
    image: SoftwareImage,
    title: "Software Engineering",
    shortText: "Discover my software engineering services.",
    longText: `Software Engineering encompasses the systematic application of engineering approaches to the development of software.
       My services include the design, development, testing, and maintenance of software applications. I follow best practices and industry standards to ensure that my software solutions 
       are reliable, scalable, and maintainable. By leveraging modern technologies and agile methodologies, I deliver high-quality software that meets myclients' needs and exceeds their expectations.`,
  },
  {
    name: "Business Intelligence",
    image: BIImage,
    title: "Business Intelligence",
    shortText: "Understand how I can enhance business intelligence.",
    longText: `Business Intelligence (BI) involves the use of technologies, processes, and practices to collect, integrate, analyze, and present business information. 
      The goal of BI is to support better business decision-making. My BI solutions provide comprehensive data insights through interactive dashboards, reporting tools, and advanced analytics. 
      By transforming raw data into meaningful and actionable information, I help organizations to improve their strategic and operational decision-making processes.`,
  },
];

function AcitivitiesWrapper() {
  const [clicked, setClicked] = useState(null);

  const handleClick = (activity) => {
    if (clicked === activity) {
      setClicked(null);
    } else {
      setClicked(activity);
    }
  };

  return (
    <div className="activities">
      {activities.map((activity) => (
        <div
          className={`activity ${
            clicked === activity.name && "activity-longText"
          }`}
          onClick={() => handleClick(activity.name)}
        >
          {clicked === activity.name ? (
            <p>{activity.longText}</p>
          ) : (
            <div className="activity-longText">
              <img src={activity.image} alt={activity.title} />
              <h3>{activity.title}</h3>
              <p>{activity.shortText}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default AcitivitiesWrapper;
