import React from "react";
import { Link, useParams } from "react-router-dom";
import AcitivitiesWrapper from "./Activities/ActivitiesWrapper";
import "./Body.css";

const ButtonMailto = ({ mailto, label }) => {
  return (
    <Link
      to="#"
      onClick={(e) => {
        window.location.href = mailto;
        e.preventDefault();
      }}
      className="email-link"
    >
      {label}
    </Link>
  );
};

const Body = () => {
  const { section } = useParams();

  return (
    <main className="body">
      {section === "home" && (
        <section id="home">
          <AcitivitiesWrapper />
        </section>
      )}
      {section === "about" && (
        <section id="about">
          <h2>About Us</h2>
          <p>Details about the company, mission, and values.</p>
        </section>
      )}
      {section === "contact" && (
        <section id="contact">
          <h2>Contact Us</h2>
          <p>For questions and inquiries you can reach me via email</p>
          <ButtonMailto
            label="Write me an E-Mail"
            mailto="mailto:thomas@datomation.nl"
          />
          <section style={{ marginTop: "20px" }}>
            <a
              href="https://www.linkedin.com/in/thomas-wierda-098459157/"
              target="_blank"
              className="email-link"
              rel="noreferrer"
            >
              Or hit me up on LinkedIn{" "}
            </a>
          </section>
        </section>
      )}
    </main>
  );
};

export default Body;
